

.loading-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    z-index: 1000;
}

.loading-items::before {
    content: '';
    display: inline-block;
    height: 100px;
    position: absolute;
}

/* Loading 2 */


.loading2 {
    position: relative;
    width: 110px;
    display: flex;
    align-items: center;
}
.loading2 span {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 30px;
    position: absolute;
}

.loading2 span:first-child {
    animation: loading2-span1 1.3s infinite;
    left: 0;
}

.loading2 span:nth-child(2) {
    animation: loading2-span2 1.3s infinite;
    left: 40px;
}

.loading2 span:nth-child(3){
    animation: loading2-span3 1.3s infinite;
    left: 80px;
}

@keyframes loading2-span1 {
    0% {
        height: 30px;
    }
    25% {
        height: 60px;
    }
    50% {
        height: 30px;
    }
    100% {
        height: 30px;
    }
}

@keyframes loading2-span2 {
    0% {
        height: 30px;
    }
    25% {
        height: 30px;
    }
    50% {
        height: 60px;
    }
    75% {
        height: 30px;
    }
    100% {
        height: 30px;
    }
}

@keyframes loading2-span3 {
    0% {
        height: 30px;
    }
    25% {
        height: 30px;
    }
    50% {
        height: 30px;
    }
    75% {
        height: 60px;
    }
    100% {
        height: 30px;
    }
}
