* {
    transition: all 0.3s ease-out;
  }
  
  html,
  body {
    height: 100%;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
  }
  
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  h3 {
    color: #262626;
    font-size: 17px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 4px;
  }
  
  p {
    font-size: 17px;
    /* font-weight: 400; */
    line-height: 20px;
    color: #666666;
  
    &.small {
      font-size: 14px;
    }
  }
  
  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: rgb(234 88 12);
    border-radius: 0 4px 0 32px;
  }
  
  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
  
  .card1 {
    display: block;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    padding: 32px 24px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -16px;
      right: -16px;
      background: rgb(234 88 12);
      height: 32px;
      width: 32px;
      border-radius: 32px;
      transform: scale(1);
      transform-origin: 50% 50%;
      transition: transform 0.25s ease-out;
    }
  
    &:hover:before {
      transform: scale(21);
    }
  }
  
  .card1:hover {
    p {
      transition: all 0.3s ease-out;
      color: rgba(255, 255, 255, 0.8);
    }
    h3 {
      transition: all 0.3s ease-out;
      color: #ffffff;
    }
  }
  
/* Trash icon styling */
.trash-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card1:hover .trash-button {
  opacity: 1;
}

.trash-icon {
  color: #ffffff; /* Optional: Change the color of the trash icon */
}

.remove-option {
  background-color: #666666;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.remove-option:hover {
  background-color: rgb(234 88 12);
  transform: scale(1.05);
}
