.post_info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
}


.user_info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.user_name_date{
    display: flex;
    flex-direction: column;

}

.like_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.detail_plan{
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
}

/*Responsive*/
@media (max-width: 768px) {
    .detail_plan{
        display: block;
        margin-top: 30vh;
        border-radius: 30px;
        margin-bottom: 20vh;
    }
}

/* @media (max-width: 1022px) {
    .post_info {
        display: none;
    }
} */